import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import Aside from '../Aside';
import NotAuthorizedAccess from '../NotAuthorizedAccess';
import HeaderContent from '../headerContent';

const { Content } = Layout;

const PrivateRoute = ({ children, headerTitle }) => {
  const { token } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);

  const renderLayout = (
    <Layout>
      <Aside
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <Layout>
        <HeaderContent title={headerTitle} />
        <Content>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
  return token ? renderLayout : <NotAuthorizedAccess reason={"Vous n'avez pas les droits d'accès"} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.shape({}).isRequired,
  headerTitle: PropTypes.string,
};

PrivateRoute.defaultProps = {
  headerTitle: '',
};

export default PrivateRoute;
