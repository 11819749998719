import api from '../utils/query';

export const getAgenciesHeadService = () => {
  const apiUrl = 'api/v1/agency_head';
  return api.get({ apiUrl });
};

export const postAgencyHeadService = (data) => {
  const apiUrl = 'api/v1/agency_head';
  return api.create(apiUrl, data);
};

export const patchAgencyHeadService = (data) => {
  const { id } = data;
  const apiUrl = `api/v1/agency_head/${id}`;
  return api.update(apiUrl, data);
};
