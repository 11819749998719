import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import DashboardList from '../shared/SharedStyle';
import TableTemplate from '../shared/TableTemplate';
import { getReferenceFileAction } from '../../business/actions/referenceFileAction';
import RecordChartsModal from '../shared/recordElements/charts/RecordChartsModal';
import { getPhoneNumberAction } from '../../business/actions/mobileAction';
import HeaderList from './agenciesDetails/HeaderList';

const MobilesList = () => {
  const { mobilesList } = useSelector((state) => state.mobiles);
  const { referenceFile } = useSelector((state) => state.referenceFile);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedMobile, setSelectedMobile] = useState(null);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedMobile) {
      dispatch(getReferenceFileAction.request(selectedMobile.id));
    }
  }, [selectedMobile]);

  const handleShowChartModal = (mobile) => {
    setSelectedMobile(mobile);
    setIsOpenModal(true);
  };

  const renderNumbersFilter = () => {
    const numberList = [];
    tableData?.map((number) => {
      numberList.push({ text: number.number, value: number.number });
      return null;
    });
    return numberList;
  };

  const renderCalibrationStatus = (calibration) => {
    if (calibration === 20) {
      return <CheckOutlined className="green" />;
    }
    if (calibration === 0) {
      return <CloseOutlined className="red" />;
    }
    const percentage = (calibration / 20) * 100;
    return `${percentage.toFixed(0)}%`;
  };

  const renderData = () => {
    const data = [];
    if (mobilesList) {
      mobilesList?.map((agenciesList) => {
        const { mobiles } = agenciesList;
        mobiles?.map((mobile) => {
          data.push({
            id: mobile.id,
            key: mobile.id,
            number: mobile.number,
            model: mobile.model,
            version: mobile.version,
            agency: agenciesList.agency,
            calibration: mobile.calibrated,
          });
          return null;
        });
        return null;
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [mobilesList]);

  const columns = [
    {
      title: t('dashboard.mobiles.number'),
      dataIndex: 'number',
      key: 'number',
      filters: renderNumbersFilter(),
      filterMode: 'tree',
      filterSearch: true,
      render: (text, mobile) => (
        <Button onClick={() => handleShowChartModal(mobile)}>
          {text}
        </Button>
      ),
      onFilter: (value, record) => {
        const res = record.number ? record.number?.startsWith(value) : null;
        return (res);
      },
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
    {
      title: t('dashboard.mobiles.model'),
      dataIndex: 'model',
      key: 'model',
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
    {
      title: t('dashboard.mobiles.version'),
      dataIndex: 'version',
      key: 'version',
      responsive: ['md', 'lg'],
      align: 'center',
      width: 45,
    },
    {
      title: t('dashboard.mobiles.calibration'),
      dataIndex: 'calibration',
      key: 'calibration',
      render: renderCalibrationStatus,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
    {
      title: t('dashboard.mobiles.agency'),
      dataIndex: 'agency',
      key: 'agency',
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
  ];

  return (
    <>
      {isOpenModal && (
        <RecordChartsModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          currentRecord={referenceFile}
          currentNumberMobile={selectedMobile?.number}
          setCurrentNumberMobile={setSelectedMobile}
          typeChart="filterCalibrationHigh"
        />
      )}
      <DashboardList width="650">
        <HeaderList
          name={t('dashboard.mobiles.title')}
          stateToUpdate={getPhoneNumberAction}
          visibleUpdateData
        />
        <TableTemplate columns={columns} data={tableData} />
      </DashboardList>
    </>

  );
};

export default MobilesList;
