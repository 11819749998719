/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import MapLeak from '../shared/map';
import { formatDate } from '../../utils/utils';
import { Context } from '../../utils/context/Intlcontext';

const LastRecordInfos = (({ lastRecordInfos, mapData }) => {
  const {
    agency, timeStamp, numberMobile, nomCommune,
  } = lastRecordInfos;
  const { initialLang } = useContext(Context);
  const { t } = useTranslation();
  return (
    <article className="last-record-infos">
      <div className="inline-block-middle">
        <p className="last-record-infos-day">{formatDate(timeStamp, t('format.date'), initialLang)}</p>
        <p className="last-record-infos-commune">{nomCommune || '--'}</p>
        <p className="last-record-infos-hour">{formatDate(timeStamp, t('format.time'))}</p>
      </div>
      <div className="inline-block-middle">
        <h2 className="last-record-infos-agency">{agency}</h2>
        <MapLeak className="mini-map" data={mapData} isMiniMap />
        <p className="last-record-infos-mobile">{numberMobile}</p>
      </div>
    </article>
  );
});

LastRecordInfos.propTypes = {
  lastRecordInfos: PropTypes.shape({
    agency: PropTypes.string.isRequired,
    timeStamp: PropTypes.number.isRequired,
    numberMobile: PropTypes.string.isRequired,
    nomCommune: PropTypes.string,
  }).isRequired,
  mapData: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    decision: PropTypes.number,
    timeStamp: PropTypes.number.isRequired,
  })),
};

LastRecordInfos.defaultProps = {
  mapData: [],
};

export default LastRecordInfos;
