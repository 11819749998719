import React from 'react';
import {
  AppstoreOutlined,
  AudioOutlined, RadarChartOutlined, SettingOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MenuComponent, SiderComponent } from './SharedStyle';
import logo from '../../assets/images/leaktracker-logo.png';

const Aside = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectedKeys = location.pathname.split('/')[1];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const getItem = (label, key, icon, children) => ({
    key,
    icon,
    children,
    label,
  });
  const items = [
    getItem(
      <Link className="item-link" to="/dashboard">{t('aside.dashboard')}</Link>,
      'dashboard',
      <AppstoreOutlined data-testid="dashboard-icon" />,
    ),
    getItem(
      <Link className="item-link" to="/supervision">{t('aside.monitoring')}</Link>,
      'supervision',
      <RadarChartOutlined data-testid="supervision-icon" />,
    ),
    getItem(
      <Link className="item-link" to="/sounds">{t('aside.records')}</Link>,
      'sounds',
      <AudioOutlined data-testid="audio-icon" />,
    ),
    getItem(
      <Link className="item-link" to="/settings">{t('aside.settings')}</Link>,
      'settings',
      <SettingOutlined data-testid="settings-icon" />,
    ),
  ];
  return (
    <SiderComponent
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      collapsedWidth={80}
      width={210}
    >
      <Link className="aside-header" to="/dashboard">
        <img className="aside-logo" src={logo} alt="Leak Tracker logo" />
      </Link>
      <MenuComponent
        selectedKeys={[selectedKeys]}
        mode="inline"
        items={items}
      />
    </SiderComponent>
  );
};

Aside.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
};

export default Aside;
