import { call, put, takeEvery } from 'redux-saga/effects';
import {
  PHONENUMBER, PHONESNUMBER,
  getCalibratedPhonesAction,
  getPhoneNumberAction, getPhonesNumberAction, getAllPhoneNumbersListAction, postPhoneNumberAction,
} from '../actions/mobileAction';
import { getPhoneNumberService, getPhonesNumberService, postMobileService } from '../services/mobileService';

export function* postPhoneNumber({ payload }) {
  try {
    yield call(postMobileService, payload);
    yield put(postPhoneNumberAction.success('Le numéro de mobile a été crée'));
  } catch (err) {
    yield put(postPhoneNumberAction.failure(err));
  }
}

const calibrationCount = (mobilesList, type) => mobilesList.filter((mobile) => (type === 'inProcess'
  ? mobile.calibration_sound_count > 0 && mobile.calibration_sound_count < 20
  : mobile.calibration_sound_count === 20)).length;

export function* getPhonesNumber({ payload }) {
  try {
    const agenciesListWithNumber = [];
    const phonesNumber = yield call(getPhonesNumberService);
    const mobilesList = phonesNumber?.data;
    let totalCalibratedPhones = 0;
    let totalCalibratedInProcess = 0;

    if (mobilesList && mobilesList.length > 0) {
      totalCalibratedPhones = calibrationCount(mobilesList);
      totalCalibratedInProcess = calibrationCount(mobilesList, 'inProcess');
    }

    if (mobilesList) {
      yield put(getAllPhoneNumbersListAction.success(mobilesList));
    }

    if (payload) {
      payload?.map((agency) => {
        const { _id: id } = agency;
        const mobileList = [];
        mobilesList?.map((mobile) => {
          const { _id: mobileId } = mobile;
          if (mobile.agency_id === id) {
            mobileList.push({
              id: mobileId,
              number: mobile.numberMobile,
              model: mobile.model,
              version: mobile.system_version,
              calibrated: mobile.calibration_sound_count,
            });
          }
          return null;
        });
        agenciesListWithNumber.push({ agency: agency.name, mobiles: mobileList });
        return null;
      });
    }
    if (phonesNumber && payload) {
      yield put(getCalibratedPhonesAction.success({
        calibrated: totalCalibratedPhones,
        inProcess: totalCalibratedInProcess,
      }));

      yield put(getPhonesNumberAction.success({
        agencies: agenciesListWithNumber,
        count: mobilesList.length,
      }));
    } else {
      yield put(getPhonesNumberAction.error('Pas de numéros de mobiles enregistrés'));
    }
  } catch (err) {
    yield put(getPhonesNumberAction.failure(err));
  }
}

export function* getPhoneNumber({ payload }) {
  try {
    const phoneNumber = yield call(getPhoneNumberService, payload);
    const data = phoneNumber && phoneNumber.data;

    if (data) {
      yield put(getPhoneNumberAction.error(true));
    } else {
      yield put(getPhoneNumberAction.success(false));
    }
  } catch (err) {
    yield put(getPhoneNumberAction.failure(err));
  }
}

export function* phoneNumberSaga() {
  yield takeEvery(PHONESNUMBER.FETCH.REQUEST, getPhonesNumber);
  yield takeEvery(PHONENUMBER.FETCH.REQUEST, getPhoneNumber);
  yield takeEvery(PHONENUMBER.POST.REQUEST, postPhoneNumber);
}
