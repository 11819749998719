import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import LastListen from '../shared/LastListen';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import { getAgenciesAction } from '../../business/actions/agencyAction';
import { getAgenciesHeadAction } from '../../business/actions/agencyHeadAction';
import { getMonitorAction } from '../../business/actions/monitoringAction';
import ProgressBar from '../shared/ProgressBar';
import { getAnalysisAction } from '../../business/actions/analysisAction';
import IndicatorCards from '../shared/cards/indicators';
import CounterCards from '../shared/cards/counters';
import Charts from './charts';
import LastRecordInfos from './LastRecordInfos';
import { getRecordsAction } from '../../business/actions/recordsAction';

const Monitoring = () => {
  const { lastRecord, recordsList, loading: recordsLoading } = useSelector(
    (state) => state.rawRecords,
  );
  const { loading: agencyLoading, agencies } = useSelector((state) => state.agencies);
  const { all } = useSelector((state) => state.mobiles);
  const { loading: analysisLoading, analysis } = useSelector((state) => state.analysis);
  const { loading: agencyHeadLoading } = useSelector((state) => state.agenciesHead);
  const { loading: mobileLoading } = useSelector((state) => state.mobiles);
  const isLoading = analysisLoading || agencyLoading || agencyHeadLoading || mobileLoading
  || recordsLoading;
  const dispatch = useDispatch();

  const getLastRecordInfos = () => {
    if (lastRecord) {
      const { timeStamp, numberMobile } = lastRecord;
      const recordInfo = analysis?.find((analyse) => analyse?.timeStamp === timeStamp);

      if (recordInfo) {
        return recordInfo;
      }
      const agencyId = all?.find((mobile) => mobile?.numberMobile === numberMobile);
      if (agencyId) {
        const agencyName = agencies.find((agency) => agency?.id === agencyId?.agencyId).name;
        return {
          ...lastRecord,
          agency: agencyName,
        };
      }
    }
    return null;
  };

  const lastRecordinfos = getLastRecordInfos();

  useEffect(() => {
    dispatch(getMonitorAction.request({ soundsList: analysis, recordsList }));
  }, [analysis]);

  useEffect(() => {
    if (!isLoading) {
      const refreshData = setInterval(() => {
        dispatch(getAnalysisAction.request());
        dispatch(getRecordsAction.request());
        dispatch(getAgenciesAction.request());
        dispatch(getAgenciesHeadAction.request());
        if (agencies) {
          dispatch(getPhonesNumberAction.request(agencies));
        }
      }, 62000);

      return () => {
        clearInterval(refreshData);
      };
    }
    return undefined;
  }, [isLoading, agencies]);

  return (
    <>
      {!isLoading ? <ProgressBar /> : <Spin className="position-absolute" />}
      <LastListen lastListen={lastRecordinfos?.timeStamp} />
      <section className="monitoring">
        <div className="monitoring-left-block">
          <IndicatorCards />
          <Charts />
        </div>
        <div className="monitoring-right-block">
          { lastRecordinfos && (
          <LastRecordInfos
            lastRecordInfos={lastRecordinfos}
            mapData={[lastRecordinfos]}
          />
          )}
          <CounterCards />
        </div>
      </section>
    </>
  );
};

export default Monitoring;
