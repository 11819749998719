import axios from 'axios';

export const apiServ = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create();

const get = async ({ apiUrl }) => {
  try {
    const response = await axiosInstance.get(`${apiServ}/${apiUrl}`);
    return response;
  } catch (err) {
    return err;
  }
};

const create = async (url, data) => {
  const urlAPI = `${apiServ}/${url}`;
  try {
    const response = await axiosInstance.post(urlAPI, data);
    return response.data;
  } catch (err) {
    return err;
  }
};

export default { get, create };
