import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

const CustomDrawer = ({
  children, width, title, setCurrentState, open, placement, height,
}) => {
  const handleCloseDrawer = () => {
    setCurrentState(null);
  };
  return (
    <Drawer
      placement={placement}
      title={title}
      width={width}
      height={height}
      open={open}
      onClose={handleCloseDrawer}
      destroyOnClose
    >
      {children}
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  placement: PropTypes.string,
  setCurrentState: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

CustomDrawer.defaultProps = {
  height: 400,
  open: false,
  placement: 'right',
  width: 910,
};
export default CustomDrawer;
