import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LineChart from '../../charts/LineChart';
import NoData from '../../NoData';
import { chartsData } from '../../../../utils/utils';

const RecordCharts = ({
  currentRecord, typeChart, width, height, isModal,
}) => {
  const { t } = useTranslation();
  const isRecordExist = currentRecord && Object.keys(currentRecord).length > 0;

  const renderChart = (chart) => {
    const {
      id, title, xTitle, yTitle, borderColor, backgroundColor, dataKey,
    } = chart;
    const data = isRecordExist ? currentRecord[dataKey] : null;
    if (data) {
      return (
        <LineChart
          key={id}
          title={t(title)}
          data={data}
          xTitle={t(xTitle)}
          yTitle={t(yTitle)}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          pointRadius={isModal ? 2 : 1}
          borderWidth={isModal ? 2 : 1}
          width={width}
          height={height}
        />
      );
    }
    return isModal ? <NoData key={id} /> : null;
  };

  const renderLineCharts = () => (
    chartsData?.map((chart) => {
      const { type } = chart;

      if (type.includes(typeChart)) {
        return renderChart(chart);
      }
      return null;
    })
  );
  return renderLineCharts();
};

RecordCharts.propTypes = {
  currentNumberMobile: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
  currentRecord: PropTypes.shape({
    pyramidDensityVector: PropTypes.arrayOf(PropTypes.number),
    densityPower: PropTypes.arrayOf(PropTypes.number),
    spectralDensityFiltered: PropTypes.arrayOf(PropTypes.number),
    numberMobile: PropTypes.string,
    filterCalibrationHigh: PropTypes.arrayOf(PropTypes.number),
  }),
  typeChart: PropTypes.string.isRequired,
};

RecordCharts.defaultProps = {
  isOpenModal: false,
  setIsOpenModal: null,
  currentNumberMobile: null,
  width: 400,
  height: 350,
  currentRecord: null,
};

export default RecordCharts;
