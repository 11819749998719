import {
  CALIBRATEDPHONES, PHONENUMBER, PHONESNUMBER, ALLPHONENUMBERS,
} from '../actions/mobileAction';

const INITIAL_STATE = {
  mobilesList: null,
  all: null,
  count: null,
  isPhoneNumberExist: null,
  loading: false,
  calibrationStatus: { calibrated: 0, inProcess: 0 },
};

const mobileReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALLPHONENUMBERS.FETCH.REQUEST:
      return { ...state, loading: true };
    case ALLPHONENUMBERS.FETCH.SUCCESS:
      return { ...state, all: payload, loading: false };
    case ALLPHONENUMBERS.FETCH.ERROR:
      return { ...state, all: payload, loading: false };
    case ALLPHONENUMBERS.FETCH.FAILURE:
      return { ...state, loading: false };
    case PHONESNUMBER.FETCH.REQUEST:
      return { ...state, loading: true };
    case PHONESNUMBER.FETCH.SUCCESS:
      return {
        ...state, mobilesList: payload.agencies, count: payload.count, loading: false,
      };
    case PHONESNUMBER.FETCH.ERROR:
      return { ...state, mobilesLis: payload, loading: false };
    case PHONENUMBER.FETCH.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBER.FETCH.SUCCESS:
      return { ...state, isPhoneNumberExist: payload, loading: false };
    case PHONENUMBER.FETCH.ERROR:
      return { ...state, isPhoneNumberExist: payload, loading: false };
    case PHONENUMBER.FETCH.FAILURE:
      return { ...state, loading: false };
    case CALIBRATEDPHONES.FETCH.REQUEST:
      return { ...state, loading: true };
    case CALIBRATEDPHONES.FETCH.SUCCESS:
      return { ...state, calibrationStatus: payload, loading: false };
    case CALIBRATEDPHONES.FETCH.ERROR:
      return { ...state, calibrationStatus: payload, loading: false };
    case PHONENUMBER.POST.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBER.POST.SUCCESS:
      return {
        ...state, list: payload, isPhoneNumberExist: null, loading: false,
      };
    case PHONENUMBER.POST.FAILURE:
      return { ...state, msg: payload, loading: false };
    default:
      return state;
  }
};

export default mobileReducer;
