import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomDrawer from '../../shared/Drawer';
import { formatDate } from '../../../utils/utils';
import MapLeak from '../../shared/map';
import MobileDetailsList from './MobileDetailsList';

const MobilesDetailsDrawer = ({ currentMobile, setCurrentMobile }) => {
  const [mapData, setMapData] = useState([]);
  const [currentHoverRowList, setCurrentHoverRowList] = useState(null);
  const showMap = mapData.length > 0;
  const { t } = useTranslation();

  const currentMobileSelected = !!currentMobile;

  const drawerTitle = t('dashboard.mobileDetails.title', {
    numberMobile: currentMobile?.numberMobile,
    dateCSV: formatDate(currentMobile?.selectedMonth, t('format.monthYear')),
  });

  return (
    <CustomDrawer
      title={drawerTitle}
      width={910}
      open={currentMobileSelected}
      setCurrentState={setCurrentMobile}
    >
      <MobileDetailsList
        currentMobile={currentMobile}
        setMapData={setMapData}
        setCurrentHoverRowList={setCurrentHoverRowList}
      />
      {showMap && (
      <MapLeak
        data={mapData}
        currentHoverRowList={currentHoverRowList}
      />
      )}
    </CustomDrawer>
  );
};

MobilesDetailsDrawer.propTypes = {
  currentMobile: PropTypes.shape({
    numberMobile: PropTypes.string.isRequired,
    selectedMonth: PropTypes.shape({}).isRequired,
  }),
  setCurrentMobile: PropTypes.func.isRequired,
};

MobilesDetailsDrawer.defaultProps = {
  currentMobile: null,
};

export default MobilesDetailsDrawer;
