import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomDrawer from '../Drawer';
import SynthesisCards from './synthesisCards';
import SynthesisCharts from './SynthesisCharts';

const SynthesisDrawer = ({
  isOpenDrawer, setIsOpenDrawer, currentRecord, type,
}) => {
  const { t } = useTranslation();

  const drawerTitle = currentRecord ? t('records.synthesisDrawer.title', {
    numberMobile: currentRecord.numberMobile,
    agency: currentRecord.agency,
    agencyHead: currentRecord.agencyHead,
    androidId: currentRecord.androidId,
  }) : '';

  return (
    <CustomDrawer
      placement="top"
      height={650}
      title={drawerTitle}
      open={isOpenDrawer}
      setCurrentState={setIsOpenDrawer}
    >
      <SynthesisCards currentRecord={currentRecord} type={type} />
      <SynthesisCharts currentRecord={currentRecord} type={type} />
    </CustomDrawer>
  );
};

SynthesisDrawer.propTypes = {
  type: PropTypes.string,
  isOpenDrawer: PropTypes.bool,
  setIsOpenDrawer: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    numberMobile: PropTypes.string,
    agency: PropTypes.string,
    agencyHead: PropTypes.string,
    androidId: PropTypes.string,
  }),
};

SynthesisDrawer.defaultProps = {
  type: null,
  isOpenDrawer: false,
  currentRecord: null,
};

export default SynthesisDrawer;
