import api from '../utils/query';

export const getPhonesNumberService = () => {
  const apiUrl = 'api/v1/list_mobile/all';
  return api.get({ apiUrl });
};

export const getPhoneNumberService = (data) => {
  const { numberMobile } = data;
  const apiUrl = `api/v1/list_mobile/phone_number/${numberMobile}`;
  return api.get({ apiUrl });
};

export const postMobileService = (data) => {
  const apiUrl = 'api/v1/list_mobile';
  return api.create(apiUrl, data);
};
