import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../shared/TableTemplate';
import DashboardList from '../shared/SharedStyle';
import HeaderList from './agenciesDetails/HeaderList';
import { getPhoneNumberAction } from '../../business/actions/mobileAction';

const MobilesByAgencyList = () => {
  const { mobilesList } = useSelector((state) => state.mobiles);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderTags = (mobile) => <Tag key={mobile} icon={<MobileOutlined />} color="#8ADFC1">{mobile}</Tag>;

  const renderData = () => {
    const data = [];
    if (mobilesList) {
      mobilesList?.map((agenciesList) => {
        data.push({
          key: agenciesList.agency,
          name: agenciesList.agency,
          mobiles: agenciesList.mobiles,
        });
        return null;
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [mobilesList]);

  const columns = [
    {
      title: t('dashboard.mobilesByAgency.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.mobilesByAgency.numberMobile'),
      dataIndex: 'mobiles',
      key: 'mobiles',
      render: (text) => text?.map((mobile) => renderTags(mobile.number)),
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <DashboardList width="600">
      <HeaderList
        name={t('dashboard.mobilesByAgency.title')}
        stateToUpdate={getPhoneNumberAction}
        visibleUpdateData
      />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

export default MobilesByAgencyList;
