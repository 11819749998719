import { ANALYSIS, ANALYSISDETAILS } from '../actions/analysisAction';

const INITIAL_STATE = {
  analysis: null, message: null, loading: false, details: [],
};

const analysisReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYSIS.FETCH.REQUEST:
      return { ...state, loading: true };
    case ANALYSIS.FETCH.SUCCESS:
      return { ...state, analysis: payload, loading: false };
    case ANALYSIS.FETCH.ERROR:
      return { ...state, message: payload, loading: false };
    case ANALYSIS.FETCH.FAILURE:
      return { ...state, loading: false };
    case ANALYSISDETAILS.FETCH.REQUEST:
      return { ...state, loading: true };
    case ANALYSISDETAILS.FETCH.SUCCESS:
      return { ...state, details: payload, loading: false };
    case ANALYSISDETAILS.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default analysisReducer;
