import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MapContainer, Marker, TileLayer,
  Tooltip,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/utils';
import waterRed from '../../../assets/images/water-red.png';
import waterBlue from '../../../assets/images/water-blue.png';
import waterGrey from '../../../assets/images/water-grey.png';
import 'leaflet/dist/leaflet.css';
import ChangeMapPosition from './ChangeMapPosition';

const ATTRIBUTION = process.env.REACT_APP_MAP_ATTRIBUTION;
const URL = process.env.REACT_APP_MAP_URL;

const MapLeak = ({
  data,
  isMiniMap,
  className,
  currentHoverRowList,
}) => {
  const [bounds, setBounds] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setBounds(data?.map((item) => [item.lat, item.long]));
  }, [data]);

  const renderMarkers = () => data?.map((item) => {
    const showTooltip = (currentHoverRowList?.key === item.key && !isMiniMap);
    let iconUrl = null;
    switch (item.decision) {
      case 1:
        iconUrl = waterRed;
        break;
      case undefined:
      case null:
        iconUrl = waterGrey;
        break;
      default:
        iconUrl = waterBlue;
    }
    const customIcon = new Icon({
      iconUrl,
      iconSize: [15, 18],
    });

    return (
      <Marker
        key={item.key}
        position={[item.lat, item.long]}
        icon={customIcon}
      >
        <Tooltip
          key={currentHoverRowList?.key}
          permanent={showTooltip}
        >
          {formatDate(item.timeStamp, t('format.dayMonthYearTime'))}
          <p className="leaflet-coordinate">
            {t('dashboard.mobileDetails.mapLatitude')}
            {' '}
            {item.lat}
          </p>
          <p className="leaflet-coordinate">
            {t('dashboard.mobileDetails.mapLongitude')}
            {' '}
            {item.long}
          </p>
        </Tooltip>
      </Marker>
    );
  });

  return (
    <MapContainer
      className={className}
      bounds={bounds}
      zoomControl={!isMiniMap}
      scrollWheelZoom
    >
      <TileLayer
        attribution={ATTRIBUTION}
        url={URL}
      />
      {renderMarkers()}
      <ChangeMapPosition bounds={bounds} />
    </MapContainer>

  );
};

MapLeak.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    decision: PropTypes.number,
    timeStamp: PropTypes.number.isRequired,
  })),
  isMiniMap: PropTypes.bool,
  className: PropTypes.string,
  currentHoverRowList: PropTypes.shape({
    key: PropTypes.string,
    timeStamp: PropTypes.number,
  }),
};

MapLeak.defaultProps = {
  data: [],
  isMiniMap: false,
  className: '',
  currentHoverRowList: null,
};
export default MapLeak;
