import { AGENCIES, AGENCIESDETAILS, AGENCY } from '../actions/agencyAction';

const INITIAL_STATE = {
  agencies: null, agenciesDetails: null, message: null, loading: false,
};

const agencyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AGENCIES.FETCH.REQUEST:
      return { ...state, loading: true };
    case AGENCIES.FETCH.SUCCESS:
      return { ...state, agencies: payload, loading: false };
    case AGENCIES.FETCH.FAILURE:
      return { ...state, loading: false };
    case AGENCY.POST.REQUEST:
      return { ...state, loading: true };
    case AGENCY.POST.SUCCESS:
      return { ...state, message: payload, loading: false };
    case AGENCY.POST.FAILURE:
      return { ...state, loading: false };
    case AGENCY.PATCH.REQUEST:
      return { ...state, loading: true };
    case AGENCY.PATCH.SUCCESS:
      return { ...state, loading: false };
    case AGENCY.PATCH.FAILURE:
      return { ...state, loading: false };
    case AGENCIESDETAILS.FETCH.REQUEST:
      return { ...state, loading: true };
    case AGENCIESDETAILS.FETCH.SUCCESS:
      return { ...state, agenciesDetails: payload, loading: false };
    case AGENCIESDETAILS.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default agencyReducer;
