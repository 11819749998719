import { call, put, takeEvery } from 'redux-saga/effects';
import {
  RECORDS, getRecordsAction,
} from '../actions/recordsAction';
import { getRecordsService } from '../services/recordsService';

export function* getRecords() {
  try {
    const records = yield call(getRecordsService);
    if (records) {
      const { data } = records;
      data.sort((a, b) => b.timeStamp - a.timeStamp);
      const lastRecord = data[0];
      yield put(getRecordsAction.success({ dataRecords: data, lastRecord }));
    } else {
      yield put(getRecordsAction.error("pas d'enregistrements"));
    }
  } catch (err) {
    yield put(getRecordsAction.failure(err));
  }
}

export function* getRecordsSaga() {
  yield takeEvery(RECORDS.FETCH.REQUEST, getRecords);
}
