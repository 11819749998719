import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../modal';
import RecordCharts from './RecordCharts';
import { formatDate } from '../../../../utils/utils';

const RecordChartsModal = ({
  isOpenModal,
  setIsOpenModal,
  typeChart,
  currentRecord,
  currentNumberMobile,
}) => {
  const { t } = useTranslation();
  const isRecordExist = currentRecord && Object.keys(currentRecord).length > 0;
  const numberMobile = currentRecord?.numberMobile || currentNumberMobile;
  const title = `${formatDate(currentRecord?.timeStamp, t('format.dayMonthYearTime'))
  } - ${numberMobile}`;

  return (
    <CustomModal
      width={isRecordExist ? 800 : 360}
      title={title}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isRecordExist={isRecordExist}
    >
      <RecordCharts
        currentRecord={currentRecord}
        currentNumberMobile={currentNumberMobile}
        typeChart={typeChart}
        isModal
      />
    </CustomModal>
  );
};

RecordChartsModal.propTypes = {
  currentNumberMobile: PropTypes.string,
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    pyramidDensityVector: PropTypes.arrayOf(PropTypes.number),
    densityPower: PropTypes.arrayOf(PropTypes.number),
    spectralDensityFiltered: PropTypes.arrayOf(PropTypes.number),
    timeStamp: PropTypes.number,
    numberMobile: PropTypes.string,
  }),
  typeChart: PropTypes.string.isRequired,
};

RecordChartsModal.defaultProps = {
  currentNumberMobile: null,
  currentRecord: null,
};

export default RecordChartsModal;
