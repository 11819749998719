import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LanguageSwitch from './LanguageSwitch';
import { handleLogout } from '../../../utils/utils';

const UserBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const iconsActions = [
    { id: 5, icon: <UserOutlined /> },
    { id: 10, icon: <LogoutOutlined onClick={() => handleLogout(dispatch, navigate)} /> },
  ];

  const renderIconsActions = () => (
    iconsActions?.map((item) => (
      <React.Fragment key={item.id}>
        {item.icon}
      </React.Fragment>
    ))
  );
  return (
    <div className="user-block">
      <LanguageSwitch />
      {renderIconsActions()}
    </div>
  );
};

export default UserBlock;
