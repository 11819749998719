import { combineReducers } from 'redux';
import recordsReducer from './recordsReducer';
import soundsReducer from './soundsReducer';
import agencyReducer from './agencyReducer';
import agencyHeadReducer from './agencyHeadReducer';
import mobileReducer from './mobileReducer';
import indicatorReducer from './indicatorReducer';
import monitoringReducer from './monitoringReducer';
import authReducer from './authReducer';
import loginReducer from './loginReducer';
import analysisReducer from './analysisReducer';
import referenceFileReducer from './referenceFileReducer';
import envelopeFilterReducer from './envelopeFilterReducer';

export const appReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  rawRecords: recordsReducer,
  sounds: soundsReducer,
  analysis: analysisReducer,
  agencies: agencyReducer,
  agenciesHead: agencyHeadReducer,
  mobiles: mobileReducer,
  referenceFile: referenceFileReducer,
  envelopeFilter: envelopeFilterReducer,
  indicators: indicatorReducer,
  monitoring: monitoringReducer,
});

const initialState = appReducer(undefined, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_POST_SUCCESS') {
    return initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
