import { put, takeEvery } from 'redux-saga/effects';
import { AUTH, postAuthAction } from '../actions/authAction';
import { loginAction } from '../actions/loginAction';

const EMAIL = process.env.REACT_APP_EMAIL;
const PASSWORD = process.env.REACT_APP_PASSWORD;

function* postAuth({ payload }) {
  try {
    const { email, password } = payload;
    if (EMAIL === email && PASSWORD === password) {
      yield put(postAuthAction.success(true));
      yield put(loginAction.success({ email }));
    }
  } catch (err) {
    yield put(postAuthAction.failure(false));
    yield put(loginAction.failure(err));
  }
}

export default function* postAuthSaga() {
  yield takeEvery(AUTH.POST.REQUEST, postAuth);
}
