import { all } from 'redux-saga/effects';
import { getRecordsSaga } from './recordsSaga';
import { getSoundsSaga } from './soundsSaga';
import { agenciesSaga } from './agencySaga';
import { agenciesHeadSaga } from './agencyHeadSaga';
import { phoneNumberSaga } from './mobileSaga';
import { getAnalysisSaga, getAnalysisDetailsSaga } from './analysisSaga';
import { getMonitoringSaga } from './monitoringSaga';
import postAuthSaga from './authSaga';
import logoutSaga from './loginSaga';
import { referenceFileSaga } from './referenceFileSaga';
import { getEnvelopeFilterSaga } from './envelopeFilterSaga';

export default function* rootSaga() {
  yield all([
    postAuthSaga(),
    logoutSaga(),
    getRecordsSaga(),
    getSoundsSaga(),
    getAnalysisSaga(),
    getAnalysisDetailsSaga(),
    agenciesSaga(),
    agenciesHeadSaga(),
    getEnvelopeFilterSaga(),
    phoneNumberSaga(),
    referenceFileSaga(),
    getMonitoringSaga(),
  ]);
}
