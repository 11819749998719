import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotAuthorizedAccess = ({ reason }) => (
  <>
    <h1>Ooooups! Que se passe-t-il ?</h1>
    <h2>
      {reason}
    </h2>
    <Link to="/">Retour</Link>
  </>
);
NotAuthorizedAccess.propTypes = {
  reason: PropTypes.string.isRequired,
};

export default NotAuthorizedAccess;
