import React, {
  useContext,
  useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import DashboardList from '../../shared/SharedStyle';
import TableTemplate from '../../shared/TableTemplate';
import { formatDate } from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';

const MobileDetailsList = ({ currentMobile, setMapData, setCurrentHoverRowList }) => {
  const { analysis } = useSelector((state) => state.analysis);
  const [tableData, setTableData] = useState([]);
  const { initialLang } = useContext(Context);
  const detailsListRef = useRef(null);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (analysis && currentMobile) {
      const { numberMobile, selectedMonth } = currentMobile;
      const selectedMonthString = formatDate(selectedMonth);
      const filteredSounds = analysis.filter((record) => {
        const mobileMatch = record.numberMobile === numberMobile;
        const timestampMatch = formatDate(record.timeStamp) === selectedMonthString;
        return mobileMatch && timestampMatch;
      });

      filteredSounds.forEach((sound) => {
        const formattedDate = formatDate(sound.timeStamp, t('format.dayTime'), initialLang);
        data.push({
          key: sound.blockChainHash,
          date: formattedDate,
          place: sound.nomCommune,
          decision: sound.decision,
          qualification: sound.qualification,
          power: sound.leakPower,
          frequency: sound.leakFrequency,
          lat: sound.lat,
          long: sound.long,
          timeStamp: sound.timeStamp,
        });
      });
      setTableData(data);
      setMapData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [analysis, currentMobile]);

  const columns = [
    {
      title: t('dashboard.mobileDetails.day'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 90,
    },
    {
      title: t('dashboard.mobileDetails.place'),
      dataIndex: 'place',
      key: 'place',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 70,
    },
    {
      title: t('dashboard.mobileDetails.decision'),
      dataIndex: 'decision',
      key: 'decision',
      align: 'center',
      render: (text, sound) => (
        sound.decision === 1 ? (
          <span className="red">{t('dashboard.mobileDetails.leak')}</span>
        ) : (
          <span>{t('dashboard.mobileDetails.noLeak')}</span>
        )
      ),
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.mobileDetails.qualification'),
      dataIndex: 'qualification',
      key: 'qualification',
      align: 'center',
      render: (text, sound) => (
        sound.qualification === sound.decision ? (
          <span>OK</span>
        ) : (
          <span>NOK</span>
        )
      ),
      width: 50,
    },
    {
      title: t('dashboard.mobileDetails.power'),
      dataIndex: 'power',
      key: 'power',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 50,
    },
    {
      title: t('dashboard.mobileDetails.frequency'),
      dataIndex: 'frequency',
      key: 'frequency',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 50,
    },
  ];

  const getCsvFileName = () => {
    const dateCSV = `${formatDate(currentMobile?.selectedMonth, t('format.monthYear'))}`;
    return t('dashboard.mobileDetails.title', { numberMobile: currentMobile?.numberMobile, dateCSV });
  };

  const csvData = useMemo(() => [
    [
      t('dashboard.mobileDetails.dayTime'),
      t('dashboard.mobileDetails.place'),
      t('dashboard.mobileDetails.decision'),
      t('dashboard.mobileDetails.qualification'),
      t('dashboard.mobileDetails.power'),
      t('dashboard.mobileDetails.frequency'),
    ],
    ...tableData.map((sound) => [
      sound.timeStamp,
      sound.place,
      sound.decision === 1 ? t('dashboard.mobileDetails.leak') : t('dashboard.mobileDetails.noLeak'),
      sound.qualification === sound.decision ? 'OK' : 'NOK',
      sound.power,
      sound.frequency,
    ]),
  ], [tableData]);

  return (
    <DashboardList ref={detailsListRef} width="800" margin="20px">
      <CSVLink className="margin-bottom-medium" data={csvData} filename={getCsvFileName()}>
        {t('dashboard.mobileDetails.downloadCSV')}
      </CSVLink>
      <TableTemplate
        columns={columns}
        data={tableData}
        setCurrentHoverRowList={setCurrentHoverRowList}
      />
    </DashboardList>
  );
};

MobileDetailsList.propTypes = {
  currentMobile: PropTypes.shape({
    numberMobile: PropTypes.string,
    selectedMonth: PropTypes.shape({}),
  }),
  setMapData: PropTypes.func.isRequired,
  setCurrentHoverRowList: PropTypes.func.isRequired,
};

MobileDetailsList.defaultProps = {
  currentMobile: {
    numberMobile: '',
    selectedMonth: '',
  },
};

export default MobileDetailsList;
