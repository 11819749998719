import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AGENCIESHEAD, AGENCYHEAD, getAgenciesHeadAction, postAgencyHeadAction,
} from '../actions/agencyHeadAction';
import { getAgenciesHeadService, patchAgencyHeadService, postAgencyHeadService } from '../services/agencyHeadService';
import { getAgenciesAction, patchAgencyAction } from '../actions/agencyAction';

export function* getAgenciesHead() {
  try {
    const agenciesHead = yield call(getAgenciesHeadService);
    const data = agenciesHead && agenciesHead.data;

    if (data) {
      yield put(getAgenciesHeadAction.success(data));
    }
  } catch (err) {
    yield put(getAgenciesHeadAction.failure(err));
  }
}

export function* postAgencyHead({ payload }) {
  try {
    const agencyHeadResponse = yield call(postAgencyHeadService, payload);
    const agenciesHead = yield call(getAgenciesHeadService);

    const agencyHeadExist = agenciesHead.data.some(
      (agencyHead) => agencyHead.name === payload.name,
    );

    if (agencyHeadResponse && agencyHeadExist) {
      yield put(getAgenciesHeadAction.success(agenciesHead.data));
      yield put(postAgencyHeadAction.success('shared.notification.agencyHead.successAdd'));
    }

    yield put(getAgenciesAction.request());
  } catch (err) {
    yield put(postAgencyHeadAction.failure(err));
  }
}

export function* patchAgencyHead({ payload }) {
  try {
    yield call(patchAgencyHeadService, payload);
    yield put(patchAgencyAction.success('Le numéro de mobile a été modifié'));
  } catch (err) {
    yield put(patchAgencyAction.failure(err));
  }
}

export function* agenciesHeadSaga() {
  yield takeEvery(AGENCIESHEAD.FETCH.REQUEST, getAgenciesHead);
  yield takeEvery(AGENCYHEAD.POST.REQUEST, postAgencyHead);
  yield takeEvery(AGENCYHEAD.PATCH.REQUEST, patchAgencyHead);
}
