import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RecordCharts from './charts/RecordCharts';
import { getReferenceFileAction } from '../../../business/actions/referenceFileAction';
import { getEnvelopeFilterAction } from '../../../business/actions/envelopeFilterAction';

const SynthesisCharts = ({ currentRecord, type }) => {
  const { all } = useSelector((state) => state.mobiles);
  const { current } = useSelector((state) => state.envelopeFilter);
  const { referenceFile } = useSelector((state) => state.referenceFile);
  const { filterCalibrationHigh, filterCalibrationDesc } = referenceFile || {};
  const { envelopeFilter } = current || {};
  const dispatch = useDispatch();

  const {
    pyramidDensityVector,
    densityPower,
    spectralDensityFiltered,
    numberMobile,
    temporalContinuityVector,
  } = currentRecord || {};

  const chartsSoundsData = {
    temporalContinuityVector,
    densityPower,
    pyramidDensityVector,
    spectralDensityFiltered,
    numberMobile,
  };
  const chartsAnalysisData = {
    densityPower,
    pyramidDensityVector,
    spectralDensityFiltered,
    filterCalibrationHigh,
    filterCalibrationDesc,
    envelopeFilter,
    numberMobile,
  };

  const getMobileId = () => {
    const mobile = all?.find((item) => item.numberMobile === numberMobile);
    if (mobile) {
      const { _id: id } = mobile;
      return id;
    }
    return null;
  };

  useEffect(() => {
    const mobileId = getMobileId();
    if (mobileId) {
      dispatch(getReferenceFileAction.request(mobileId));
    }
  }, [numberMobile]);

  useEffect(() => {
    dispatch(getEnvelopeFilterAction.request());
  }, []);
  return (
    <div className="synthesis-charts">
      <RecordCharts currentRecord={type === 'analysis' ? chartsAnalysisData : chartsSoundsData} typeChart="all" height={200} width={280} />
    </div>
  );
};

SynthesisCharts.propTypes = {
  type: PropTypes.string.isRequired,
  currentRecord: PropTypes.shape({
    pyramidDensityVector: PropTypes.arrayOf(PropTypes.number),
    densityPower: PropTypes.arrayOf(PropTypes.number),
    spectralDensityFiltered: PropTypes.arrayOf(PropTypes.number),
    numberMobile: PropTypes.string.isRequired,
  }),
};

SynthesisCharts.defaultProps = {
  currentRecord: null,
};

export default SynthesisCharts;
