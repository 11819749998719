import { notification } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import fr from 'dayjs/locale/fr';
import 'dayjs/locale/es';
import { CheckOutlined, CloseOutlined, StopOutlined } from '@ant-design/icons';
import {
  blueAzur2Color,
  blueAzurColor,
  blueLight2Color,
  blueLightColor, bluePrimaryColor, greenLightColor, orangeColor, redColor,
} from '../assets/styles/colors';
import { logoutAction } from '../business/actions/loginAction';

dayjs.extend(relativeTime);
dayjs.locale(fr);

export const regexEmail = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
export const regexMDP = /^(?=.*[~@!%=+\-*?&[\]{}()'\\|`_^])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
export const regexMobileNumber = /^\d{10}$/;

export const getQualificationValue = (qualification) => {
  switch (qualification) {
    case 0:
      return 'qualificationOK';
    case 1:
      return 'qualificationNOK';
    default:
      return 'notLabeled';
  }
};

export const getIconQualification = (qualification, decision) => {
  if (qualification === 2) {
    return <StopOutlined className="grey" />;
  }
  if (qualification === decision) {
    return <CheckOutlined className="green" />;
  }
  return <CloseOutlined className="red" />;
};

export const getLeakValue = (decision) => (decision === 1 ? 'leak' : 'noLeak');

export const optionsLanguages = [
  {
    id: 5, value: 'en', label: 'en',
  },
  {
    id: 20, value: 'fr', label: 'fr',
  },
  {
    id: 30, value: 'es', label: 'es',
  },
];

export const onSuccess = (message, description) => {
  notification.success({
    message,
    description,
  });
};

export const onError = () => {
  notification.error({
    message: 'Oops',
    description: 'Une erreur est survenue',
  });
};

export const renderSelectValues = (state, isRequired) => {
  const arrayOptions = [];

  if (!isRequired) {
    arrayOptions.push({ value: '', label: "pas d'agence mère" });
  }

  state?.map((item) => {
    const { _id, name } = item;
    arrayOptions.push({ value: _id, label: name });
    return null;
  });
  return arrayOptions;
};

export const monitoringComponents = [
  {
    id: 5,
    title: 'analysis',
    type: 'indicator',
    source: 'analysis',
    fill: blueLightColor,
    order: 5,
  },
  {
    id: 10,
    title: 'leak',
    type: 'indicator',
    source: 'leak',
    fill: redColor,
    order: 10,
  },
  {
    id: 20,
    title: 'noLeak',
    type: 'indicator',
    source: 'noLeak',
    fill: bluePrimaryColor,
    order: 20,
  },
  {
    id: 30,
    title: 'noSignal',
    type: 'indicator',
    source: 'noSignal',
    fill: orangeColor,
    order: 30,
  },
  {
    id: 110,
    title: 'agencies',
    type: 'counter',
    source: 'agencies',
    order: 110,
  },
  {
    id: 120,
    title: 'headAgencies',
    type: 'counter',
    source: 'agenciesHead',
    order: 120,
  },
  {
    id: 130,
    title: 'mobiles',
    type: 'counter',
    source: 'mobiles',
    order: 130,
  },
  {
    id: 140,
    title: 'calibratedPhones',
    type: 'counter',
    source: 'calibratedPhones',
    order: 140,
  },
  {
    id: 145,
    title: 'calibratedInProcess',
    type: 'counter',
    source: 'calibratedInProcess',
    order: 145,
  },
];

export const getSourceState = (source, state) => state[source];

export const formatDate = (timestamp, format = 'MM/YYYY', language = 'fr') => {
  dayjs.locale(language);
  return dayjs(timestamp).format(format);
};

export const formatTimeAgo = (timestamp, language, noRecord) => {
  dayjs.locale(language);
  const diff = () => {
    if (timestamp) {
      return dayjs(timestamp).fromNow();
    }
    return noRecord;
  };
  return diff();
};

export const filterByMonth = (timestamps, selectedMonth) => {
  const selectedMonthFormat = formatDate(selectedMonth);
  return timestamps.filter((timestamp) => {
    const timestampFormat = dayjs(timestamp);
    return formatDate(timestampFormat) === selectedMonthFormat;
  });
};

export const getBoundsMinMax = (bounds) => {
  const latitudes = bounds?.map((coord) => coord[0]);
  const longitudes = bounds?.map((coord) => coord[1]);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  return [[minLat, minLng], [maxLat, maxLng]];
};

export const setUniqueList = (arr, key) => (
  [...new Map(arr && arr?.map((item) => [item[key], item])).values()]
);

export const setArrayOfUniqueItems = ({ data, type }) => {
  const uniqueItemSet = new Set();
  data?.forEach((item) => {
    if (type === 'agencies') {
      uniqueItemSet.add(item.agency);
    }
    if (type === 'phone') {
      uniqueItemSet.add(item.numberMobile);
    }
  });
  const uniqueItem = Array.from(uniqueItemSet);
  return uniqueItem;
};

export const showErrorNotification = (message, description) => {
  notification.error({
    message,
    description,
    closable: true,
    duration: 2,
  });
};

export const handleLogout = (dispatch, navigate) => {
  dispatch(logoutAction.request());
  navigate('/');
};

export const chartsData = [
  {
    id: 5,
    title: 'shared.chart.brutDensity',
    dataKey: 'pyramidDensityVector',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.frequency',
    borderColor: blueAzurColor,
    backgroundColor: blueAzurColor,
    type: ['all', 'analysis', 'pyramidDensityVector'],
  },
  {
    id: 10,
    title: 'shared.chart.filteredDensity',
    dataKey: 'densityPower',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueAzur2Color,
    backgroundColor: blueAzur2Color,
    type: ['all', 'analysis', 'densityPower'],
  },
  {
    id: 15,
    title: 'shared.chart.spectralDensityFiltered',
    dataKey: 'spectralDensityFiltered',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: bluePrimaryColor,
    backgroundColor: bluePrimaryColor,
    type: ['all', 'spectralDensityFiltered'],
  },
  {
    id: 20,
    title: 'shared.chart.mobileFilterCalibrationHigh',
    dataKey: 'filterCalibrationHigh',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLight2Color,
    backgroundColor: blueLight2Color,
    type: ['all', 'referenceFile', 'filterCalibrationHigh'],
  },
  {
    id: 30,
    title: 'shared.chart.mobileFilterCalibrationDesc',
    dataKey: 'filterCalibrationDesc',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLightColor,
    backgroundColor: blueLightColor,
    type: ['all', 'referenceFile', 'filterCalibrationDesc'],
  },
  {
    id: 35,
    title: 'shared.chart.temporalContinuityVector',
    dataKey: 'temporalContinuityVector',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: blueLightColor,
    backgroundColor: blueLightColor,
    type: ['all', 'temporalContinuityVector'],
  },
  {
    id: 25,
    title: 'shared.chart.envelopeFilter',
    dataKey: 'envelopeFilter',
    xTitle: 'shared.chart.frequency',
    yTitle: 'shared.chart.power',
    borderColor: greenLightColor,
    backgroundColor: greenLightColor,
    type: ['all', 'envelopeFilter'],
  },
];
