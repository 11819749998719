import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const defaultValue = { token: false };
export const AuthContext = createContext(defaultValue);

const AuthContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.auth);

  const contextValue = useMemo(() => ({
    token,
  }), [token]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
