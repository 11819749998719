import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Home from './components/Home';
import AuthContextProvider from './utils/context/AuthContext';
import NotFound from './components/shared/NotFound';
import store, { persistor } from './business/store';
import Settings from './components/Settings';
import MobileSettings from './components/Settings/MobileSettings';
import ArchivesSettings from './components/Settings/ArchivesSettings';
import Sounds from './components/sounds';
import Dashboard from './components/Dashboard';
import Supervision from './components/supervision';
import PrivateRoute from './components/shared/routes/PrivateRoute';
import PublicRoute from './components/shared/routes/PublicRoute';
import FooterComponent from './components/shared/Footer';
import IntlContextProvider from './utils/context/Intlcontext';
import AgencySettings from './components/Settings/AgencySettings';
import AgencyHeadSettings from './components/Settings/AgencyHeadSettings';

const routes = [
  {
    id: 5,
    exact: true,
    path: '/',
    Component: Home,
    isPublic: true,
  },
  {
    id: 10,
    exact: true,
    path: '/dashboard',
    headerTitle: 'headerContent.dashboardTitle',
    Component: Dashboard,
  },
  {
    id: 15,
    exact: true,
    path: '/sounds',
    headerTitle: 'headerContent.recordsTitle',
    Component: Sounds,
  },
  {
    id: 20,
    exact: true,
    path: '/supervision',
    headerTitle: 'headerContent.supervisionTitle',
    Component: Supervision,
  },
  {
    id: 25,
    exact: true,
    path: '/settings',
    headerTitle: 'headerContent.settingsTitle',
    Component: Settings,
  },
  {
    id: 30,
    exact: true,
    path: '/settings/mobile',
    Component: MobileSettings,
  },
  {
    id: 31,
    exact: true,
    path: '/settings/agencyHead',
    Component: AgencyHeadSettings,
  },
  {
    id: 32,
    exact: true,
    path: '/settings/agency',
    Component: AgencySettings,
  },
  {
    id: 33,
    exact: true,
    path: '/settings/archives',
    Component: ArchivesSettings,
  },
  {
    id: 35,
    exact: true,
    path: '/*',
    Component: NotFound,
    isPublic: true,
  },
];

const App = () => (
  <Router>
    <Routes>
      { routes.map((route) => {
        const {
          id, exact, path, Component, headerTitle, isPublic,
        } = route;
        const RouteWrapper = isPublic ? PublicRoute : PrivateRoute;
        return (
          <Route
            key={id}
            exact={exact}
            path={path}
            element={(
              <RouteWrapper headerTitle={headerTitle}>
                <Component />
              </RouteWrapper>
            )}
          />
        );
      })}
    </Routes>
  </Router>
);

const AppWrapper = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthContextProvider>
        <IntlContextProvider>
          <App />
        </IntlContextProvider>
        <FooterComponent />
      </AuthContextProvider>
    </PersistGate>
  </Provider>
);

export default AppWrapper;
