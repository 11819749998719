import React, { useState } from 'react';
import {
  Button, Form, Input, Select, Tag,
} from 'antd';
import { PropTypes } from 'prop-types';

const FormTemplate = (props) => {
  const {
    nameForm,
    className,
    variables,
    useForm,
    buttonValidText,
    onFinishApiCall,
    onSuccess,
    layout,
    onError,
    loading,
    initialValues,
  } = props;
  const formLayout = layout || 'vertical';
  const { Password } = Input;
  const [success, setSuccess] = useState(true);

  const renderInput = ({
    placeholder,
    type,
    prefix,
    sufix,
    selectValues,
    onChange,
    maxLength,
    list,
    setList,
    onClick,
    isDisabledBtn,
  }) => {
    const handleRemoveToList = (item) => {
      const newList = list.filter((tag) => tag !== item);
      setList(newList);
    };

    const renderTagsList = () => {
      if (list) {
        return list.map((item) => (
          <Tag
            closable={list.indexOf(item) !== 0}
            onClose={() => handleRemoveToList(item)}
            key={item}
          >
            {item}
          </Tag>
        ));
      }
      return null;
    };

    if (type === 'select') {
      return <Select placeholder={placeholder} options={selectValues} />;
    }
    if (type === 'password') {
      return (
        <Password
          prefix={prefix}
          suffix={sufix}
          placeholder={placeholder}
        />
      );
    }

    if (type === 'addToList') {
      return (
        <div>
          {renderTagsList()}
          <Input
            prefix={prefix}
            className="input-add-to-list"
            placeholder={placeholder}
            autoComplete="off"
            type={type}
            onChange={onChange}
            maxLength={maxLength}
          />
          <Button type="primary" className="btn-add-to-list" disabled={isDisabledBtn} onClick={onClick}>ajouter</Button>
        </div>
      );
    }
    return (
      <Input
        className="form-input"
        prefix={prefix}
        placeholder={placeholder}
        autoComplete="off"
        type={type}
        onChange={onChange}
        maxLength={maxLength}
      />
    );
  };

  const handleError = (error) => {
    if (error?.response?.data) {
      const { errors } = error.response.data;

      if (errors) {
        const keys = Object.keys(errors);
        console.log('🚀 ~ file: formTemplate.jsx ~ line 86 ~ handleError ~ keys', keys);
      }
    }
  };

  const onFinish = async (values) => {
    try {
      const request = {};
      variables.forEach((variable) => {
        request[variable.name] = values[variable.name];
      });
      const array = await onFinishApiCall(request);
      if (array) {
        array.forEach((item) => {
          if (item.status === 'rejected') {
            handleError(item.reason);
            setSuccess(false);
          }
          if (!loading) {
            setSuccess(true);
          }
        });
      }
      setTimeout(() => {
        if (success) {
          onSuccess();
        }
      }, 1000);
    } catch (error) {
      handleError(error);
      if (onError) {
        console.log('formTemplate onFinish error : ', error);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="form-block">
        <Form
          layout={formLayout}
          name={nameForm}
          labelAlign="top"
          requiredMark
          colon={false}
          initialValues={{ remember: true, ...initialValues }}
          onFinish={onFinish}
          className={className}
          form={useForm}
        >
          {variables?.map((variable) => {
            if (!variable.noUpdate) {
              return (
                <Form.Item
                  key={variable.name}
                  label={variable.label}
                  extra={variable.extra}
                  name={variable.name}
                  hasFeedback
                  rules={variable.rules}
                  help={variable.help}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  {renderInput(variable)}
                </Form.Item>
              );
            } return null;
          })}
          <Form.Item key="As--32" className="submit" name="validate">
            <Button type="primary" htmlType="submit">
              {buttonValidText}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>

  );
};

FormTemplate.propTypes = {
  nameForm: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  useForm: PropTypes.shape().isRequired,
  buttonValidText: PropTypes.string.isRequired,
  onFinishApiCall: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  layout: PropTypes.string,
  onError: PropTypes.func,
  loading: PropTypes.bool,
};

FormTemplate.defaultProps = {
  layout: 'vertical',
  onError: () => {},
  loading: true,
  initialValues: {},
};

export default FormTemplate;
