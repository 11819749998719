import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const ENVELOPEFILTER = createRequestTypes('ENVELOPEFILTER', [FETCH]);

export const getEnvelopeFilterAction = {
  request: (payload) => action(ENVELOPEFILTER.FETCH.REQUEST, payload),
  success: (data) => action(ENVELOPEFILTER.FETCH.SUCCESS, data),
  failure: (error) => action(ENVELOPEFILTER.FETCH.FAILURE, error),
};
