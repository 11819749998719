import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../shared/TableTemplate';
import DashboardList from '../shared/SharedStyle';
import HeaderList from './agenciesDetails/HeaderList';
import { getAgenciesAction } from '../../business/actions/agencyAction';

const AgenciesList = () => {
  const { agencies } = useSelector((state) => state.agencies);
  const { agenciesHead } = useSelector((state) => state.agenciesHead);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (agencies) {
      agencies?.map((agency) => {
        const { _id: id } = agency;
        data.push({
          key: id,
          name: agency.name,
          agencyHead: agency.agency_head_id,
          email: agency.mail,
        });
        return null;
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [agencies]);

  const columns = [
    {
      title: t('dashboard.agency.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.agency.agencyHead'),
      dataIndex: 'agencyHead',
      key: 'agencyHead',
      render: (text) => agenciesHead?.map((agency) => {
        const { _id } = agency;
        return text === _id && agency.name;
      }),
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.agency.email'),
      dataIndex: 'email',
      key: 'email',
      render: (text) => text && text?.map((item) => <div key={item}>{item}</div>),
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <DashboardList width="800">
      <HeaderList
        name={t('dashboard.agency.title')}
        stateToUpdate={getAgenciesAction}
        visibleUpdateData
      />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

export default AgenciesList;
