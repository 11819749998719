import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tag } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../shared/TableTemplate';
import DashboardList from '../shared/SharedStyle';
import HeaderList from './agenciesDetails/HeaderList';
import { getAgenciesHeadAction } from '../../business/actions/agencyHeadAction';

const AgenciesHeadList = () => {
  const { agencies } = useSelector((state) => state.agencies);
  const { agenciesHead } = useSelector((state) => state.agenciesHead);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (agenciesHead) {
      agenciesHead?.map((agencyHead) => {
        const { _id: id } = agencyHead;
        data.push({
          key: id,
          name: agencyHead.name,
          agency: id,
          email: agencyHead.mail,
        });
        return null;
      });
      setTableData(data);
    }
  };

  const renderEmail = (item, index) => <div key={item + index}>{item}</div>;

  useEffect(() => {
    renderData();
  }, [agenciesHead]);

  const columns = [
    {
      title: t('dashboard.agencyHead.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.agencyHead.associatedAgencies'),
      dataIndex: 'agency',
      key: 'agency',
      render: (text) => {
        const associatedAgencies = agencies.filter((agency) => agency.agency_head_id === text);
        return associatedAgencies?.map((agency) => {
          const { _id: agencyId, name } = agency;
          return <Tag key={agencyId + text} icon={<TagOutlined />} color="#8ADFC1">{name}</Tag>;
        });
      },
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text && text?.map((item, index) => renderEmail(item, index)),
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <DashboardList width="800">
      <HeaderList
        name={t('dashboard.agencyHead.title')}
        stateToUpdate={getAgenciesHeadAction}
        visibleUpdateData
      />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

export default AgenciesHeadList;
