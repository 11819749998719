import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { MobileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FormTemplate from './FormTemplate';
import { getAgenciesAction } from '../../../business/actions/agencyAction';
import { regexMobileNumber, renderSelectValues } from '../../../utils/utils';
import { getPhoneNumberAction, postPhoneNumberAction } from '../../../business/actions/mobileAction';
import { getAgenciesHeadAction } from '../../../business/actions/agencyHeadAction';

const MobileForm = () => {
  const { agenciesHead } = useSelector((state) => state.agenciesHead);
  const { agencies } = useSelector((state) => state.agencies);
  const { isPhoneNumberExist } = useSelector((state) => state.mobiles);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAgenciesAction.request());
    dispatch(getAgenciesHeadAction.request());
  }, []);

  const onCancel = () => {
    form.resetFields();
  };

  const onFinish = (data) => {
    const resultPromise = [];
    const { agencyId, agencyHead, numberMobile } = data;
    if ((agencyId || agencyHead) && !isPhoneNumberExist) {
      resultPromise.push(dispatch(postPhoneNumberAction.request({
        numberMobile,
        agency_id: agencyId,
        agency_head_id: agencyHead,
        androidId: '',
        calibration_sound_count: 0,
        model: '',
        system_version: '',
      })));
    } else {
      if (!(agencyId || agencyHead)) {
        notification.error({
          message: t('shared.notification.error'),
          description: t('shared.notification.mobile.selectAgency'),
        });
      }
      if (isPhoneNumberExist) {
        notification.error({
          message: t('shared.notification.error'),
          description: t('shared.notification.mobile.mobileAlreadyExist'),
        });
      }
      return Promise.reject();
    }
    return Promise.allSettled(resultPromise);
  };

  const onSuccess = () => {
    notification.success({
      message: t('shared.notification.success'),
      description: t('shared.notification.mobile.successAddMobile'),
    });
    form.resetFields();
  };

  const handleInputMobileChange = (event) => {
    const { value } = event.target;
    if (value.length === 10) {
      dispatch(getPhoneNumberAction.request({ numberMobile: value }));
    }
  };

  const variables = [
    {
      label: t('settings.mobile.mobileNumber'),
      name: 'numberMobile',
      prefix: <MobileOutlined />,
      maxLength: 10,
      onChange: (event) => handleInputMobileChange(event),
      rules: [
        {
          required: true,
          pattern: regexMobileNumber,
          message: t('settings.mobile.mobileNumberHelp'),
        },
      ],
      placeholder: t('settings.mobile.placeholderMobileNumber'),
    },
    {
      label: t('settings.mobile.agency'),
      name: 'agencyId',
      type: 'select',
      selectValues: renderSelectValues(agencies),
      placeholder: t('settings.mobile.placeholderAgency'),
    },
    {
      label: t('settings.mobile.headAgency'),
      name: 'agencyHead',
      type: 'select',
      selectValues: renderSelectValues(agenciesHead),
      placeholder: t('settings.mobile.placeholderHeadAgency'),
    },
  ];

  return (
    <FormTemplate
      className="settings-form"
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onCancel={onCancel}
    />
  );
};

export default MobileForm;
