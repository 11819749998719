import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SynthesisCard from './SynthesisCard';
import { formatDate, getLeakValue, getQualificationValue } from '../../../../utils/utils';

const SynthesisCards = ({ currentRecord, type }) => {
  const {
    fileName,
    timeStamp,
    timeStampCalcul,
    lat,
    long,
    nomCommune,
    codeDepartement,
    decision,
    qualification,
    leakFrequency,
    leakPower,
    powerSevenCalibration,
    powerSevenEnv,
    pyramidResult,
    coefficient,
  } = currentRecord;

  const qualificationValue = getQualificationValue(qualification);
  const leakValue = getLeakValue(decision);
  const { t } = useTranslation();

  const recordInfosCard = [
    { label: t('records.synthesisDrawer.filename'), value: fileName },
    { label: t('records.synthesisDrawer.recordDate'), value: formatDate(timeStamp, t('format.dayMonthTime')) },
    { label: t('records.synthesisDrawer.analysisDate'), value: formatDate(timeStampCalcul * 1000, t('format.dayMonthTime')) },
  ];

  const placeCard = [
    { label: t('records.synthesisDrawer.latitude'), value: lat },
    { label: t('records.synthesisDrawer.longitude'), value: long },
    { label: t('records.synthesisDrawer.commune'), value: nomCommune },
    { label: t('records.synthesisDrawer.codeDept'), value: codeDepartement },
  ];

  const recordResultCard = [
    { label: t('records.synthesisDrawer.decision'), value: t(`records.synthesisDrawer.${leakValue}`) },
    { label: t('records.synthesisDrawer.qualification'), value: t(`records.synthesisDrawer.${qualificationValue}`) },
    { label: t('records.synthesisDrawer.signalFrequency'), value: leakFrequency },
    { label: t('records.synthesisDrawer.signalPower'), value: leakPower },
    { label: t('records.synthesisDrawer.coefficient'), value: coefficient || 'N/A' },

  ];

  const analysisResultCard = [
    { label: t('records.synthesisDrawer.powerSevenCalibration'), value: powerSevenCalibration?.toFixed(2) },
    { label: t('records.synthesisDrawer.powerSevenEnv'), value: powerSevenEnv?.toFixed(2) },
    { label: t('records.synthesisDrawer.pyramidResult'), value: pyramidResult },
  ];

  const synthesisCards = [
    { title: t('records.synthesisDrawer.recordInfos'), details: recordInfosCard },
    { title: t('records.synthesisDrawer.place'), details: placeCard, mapData: [currentRecord] },
    { title: t('records.synthesisDrawer.recordResult'), details: recordResultCard },
  ];

  const analysisResult = { title: t('records.synthesisDrawer.analysisResult'), details: analysisResultCard };
  if (type === 'analysis') {
    synthesisCards.push(analysisResult);
  }

  const renderSynthesisCards = () => synthesisCards?.map(({ title, details, mapData }) => (
    <SynthesisCard key={title} title={title} details={details} mapData={mapData} width={350} />
  ));

  return (
    <div className="synthesis-cards">
      {renderSynthesisCards()}
    </div>
  );
};

SynthesisCards.propTypes = {
  type: PropTypes.string.isRequired,
  currentRecord: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    timeStamp: PropTypes.number.isRequired,
    timeStampCalcul: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    nomCommune: PropTypes.string.isRequired,
    codeDepartement: PropTypes.string.isRequired,
    decision: PropTypes.number.isRequired,
    qualification: PropTypes.number.isRequired,
    leakFrequency: PropTypes.number.isRequired,
    leakPower: PropTypes.number.isRequired,
    coefficient: PropTypes.number,
    powerSevenCalibration: PropTypes.number.isRequired,
    powerSevenEnv: PropTypes.number,
    pyramidResult: PropTypes.number.isRequired,
  }),
};

SynthesisCards.defaultProps = {
  currentRecord: null,
};

export default SynthesisCards;
