import { ENVELOPEFILTER } from '../actions/envelopeFilterAction';

const INITIAL_STATE = { current: null, loading: false };

const envelopeFilterReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENVELOPEFILTER.FETCH.REQUEST:
      return { ...state, loading: true };
    case ENVELOPEFILTER.FETCH.SUCCESS:
      return { ...state, current: payload, loading: false };
    case ENVELOPEFILTER.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default envelopeFilterReducer;
