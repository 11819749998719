import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';

export const AGENCIESHEAD = createRequestTypes('AGENCIESHEAD', [FETCH]);
export const AGENCYHEAD = createRequestTypes('AGENCYHEAD', [POST, PATCH]);

export const getAgenciesHeadAction = {
  request: (payload) => action(AGENCIESHEAD.FETCH.REQUEST, payload),
  success: (data) => action(AGENCIESHEAD.FETCH.SUCCESS, data),
  failure: (error) => action(AGENCIESHEAD.FETCH.FAILURE, error),
};

export const postAgencyHeadAction = {
  request: (payload) => action(AGENCYHEAD.POST.REQUEST, payload),
  success: (data) => action(AGENCYHEAD.POST.SUCCESS, data),
  failure: (error) => action(AGENCYHEAD.POST.FAILURE, error),
};

export const patchAgencyHeadAction = {
  request: (payload) => action(AGENCYHEAD.PATCH.REQUEST, payload),
  success: (data) => action(AGENCYHEAD.PATCH.SUCCESS, data),
  failure: (error) => action(AGENCYHEAD.PATCH.FAILURE, error),
};
