import { REFERENCEFILE } from '../actions/referenceFileAction';

const INITIAL_STATE = { referenceFile: null, message: null, loading: false };

const referenceFileReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case REFERENCEFILE.FETCH.REQUEST:
      return { ...state, loading: true };
    case REFERENCEFILE.FETCH.SUCCESS:
      return { ...state, referenceFile: payload, loading: false };
    case REFERENCEFILE.FETCH.ERROR:
      return { ...state, message: payload, loading: false };
    case REFERENCEFILE.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default referenceFileReducer;
