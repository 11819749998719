import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DashboardList from '../../shared/SharedStyle';
import DoughnutChart from '../../shared/charts/DoughnutChart';

const QualificationChart = ({ qualifications }) => {
  const { t } = useTranslation();
  const labelsChart = [
    t('monitoring.chartQualification.qualificationOK'),
    t('monitoring.chartQualification.qualificationNOK'),
    t('monitoring.chartQualification.noQualification'),
  ];

  return (
    <DashboardList width={250}>
      <h3 className="title-dark-default">{t('monitoring.chartQualification.title')}</h3>
      <DoughnutChart dataChart={qualifications} labelsData={labelsChart} color={1} />
    </DashboardList>
  );
};

QualificationChart.propTypes = {
  qualifications: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default QualificationChart;
