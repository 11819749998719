import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const REFERENCEFILE = createRequestTypes('REFERENCEFILE', [FETCH]);

export const getReferenceFileAction = {
  request: (payload) => action(REFERENCEFILE.FETCH.REQUEST, payload),
  success: (data) => action(REFERENCEFILE.FETCH.SUCCESS, data),
  error: (message) => action(REFERENCEFILE.FETCH.ERROR, message),
  failure: (error) => action(REFERENCEFILE.FETCH.FAILURE, error),
};
