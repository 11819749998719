import React, { useContext, useEffect } from 'react';
import { Form } from 'antd';
import { LockOutlined, UserOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  onError, onSuccess, regexEmail, regexMDP,
} from '../../utils/utils';
import FormTemplate from '../shared/Forms/FormTemplate';
import { postAuthAction } from '../../business/actions/authAction';
import { AuthContext } from '../../utils/context/AuthContext';

const LoginForm = () => {
  const { token } = useContext(AuthContext);
  const successMessage = 'Vous êtes connecté';
  const successDesc = 'Vous êtes sur le tableau de bord';
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      navigate('/dashboard');
    }
  }, [token]);

  const variables = [
    {
      label: t('login.email'),
      name: 'email',
      placeholder: t('login.emailPlaceholder'),
      prefix: <UserOutlined />,
      rules: [
        {
          required: true,
          type: 'email',
          pattern: regexEmail,
          message: t('login.emailHelp'),
        },
      ],
    },
    {
      label: t('login.password'),
      name: 'password',
      placeholder: t('login.passwordPlaceholder'),
      prefix: <LockOutlined />,
      sufix: <EyeTwoTone />,
      type: 'password',
      help: t('login.passwordHelp'),
      rules: [
        {
          required: true,
          pattern: regexMDP,
        },
      ],
    },
  ];

  const onFinish = ({ email, password }) => {
    if (email && password) {
      dispatch(postAuthAction.request({ email, password }));
    }
  };

  return (
    <div className="login-form">
      <FormTemplate
        nameForm="signin"
        useForm={form}
        variables={variables}
        onFinishApiCall={onFinish}
        onSuccess={() => onSuccess(successMessage, successDesc)}
        onError={onError}
        buttonValidText={t('shared.formTemplate.submit')}
      />
    </div>
  );
};

export default LoginForm;
