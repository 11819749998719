import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AgenciesList from './AgenciesList';
import AgenciesHeadList from './AgenciesHeadList';
import { getAgenciesHeadAction } from '../../business/actions/agencyHeadAction';
import { getAgenciesAction } from '../../business/actions/agencyAction';
import { PageContainer } from '../shared/SharedStyle';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import MobilesList from './MobilesList';
import AgenciesDetails from './agenciesDetails';
import { getRecordsAction } from '../../business/actions/recordsAction';
import SkeletonList from '../shared/skeletons/SkeletonList';
import MobilesByAgencyList from './MobilesByAgencyList';
import { getAnalysisAction, getAnalysisDetailsAction } from '../../business/actions/analysisAction';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { analysis } = useSelector((state) => state.analysis);
  const { recordsList } = useSelector((state) => state.rawRecords);
  const { agenciesHead, loading: agenciesHeadLoading } = useSelector((state) => state.agenciesHead);
  const { agencies, loading: agenciesLoading } = useSelector((state) => state.agencies);
  const { mobilesList, loading: mobilesLoading } = useSelector((state) => state.mobiles);
  const isAgenciesHeadDataLoaded = agenciesHead && !agenciesHeadLoading;
  const isMobilesDataLoaded = mobilesList && !mobilesLoading;
  const isAgenciesDataLoaded = agencies && !agenciesLoading;
  const isAllAgenciesDataLoaded = isAgenciesHeadDataLoaded && isAgenciesDataLoaded;

  const componentsList = [
    { id: 5, isLoaded: isAllAgenciesDataLoaded, component: <AgenciesHeadList /> },
    { id: 10, isLoaded: isAgenciesDataLoaded, component: <AgenciesList /> },
    {
      id: 20,
      isLoaded: isMobilesDataLoaded,
      component: (
        <>
          <MobilesByAgencyList />
          <MobilesList />
        </>
      ),
      skeletonProps: { width: '600', rows: 3 },
    },
  ];

  useEffect(() => {
    if (analysis === null) {
      dispatch(getAnalysisAction.request());
      dispatch(getAgenciesAction.request());
      dispatch(getAgenciesHeadAction.request());
      dispatch(getRecordsAction.request());
    } else if (recordsList) {
      dispatch(getAnalysisDetailsAction.request({ analysis, recordsList }));
    }
  }, [analysis, recordsList]);

  useEffect(() => {
    if (agencies) {
      dispatch(getPhonesNumberAction.request(agencies));
    }
  }, [agencies]);

  const renderComponentsList = () => componentsList?.map((item) => {
    const { width, rows } = item.skeletonProps || {};
    return item.isLoaded
      ? <React.Fragment key={item.id}>{item.component}</React.Fragment>
      : <SkeletonList key={item.id} width={width} rows={rows} />;
  });

  return (
    <PageContainer className="dashboard" screenHeight={window.innerHeight}>
      <AgenciesDetails />
      {renderComponentsList()}
    </PageContainer>
  );
};
export default Dashboard;
