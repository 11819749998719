import React from 'react';
import { Table } from 'antd';
import { PropTypes } from 'prop-types';

const TableTemplate = ({
  columns, data, expandable, typeList, setCurrentHoverRowList,
}) => {
  const scroll = typeList === 'records' ? { y: window.innerHeight - 340 } : { y: 220 };

  const onRow = (record) => ({
    onMouseEnter: () => setCurrentHoverRowList(record),
    onMouseLeave: () => setCurrentHoverRowList(null),
  });

  return (
    <Table
      onRow={onRow}
      columns={columns}
      dataSource={data}
      size="small"
      bordered
      scroll={scroll}
      expandable={expandable}
      pagination={false}
    />
  );
};

TableTemplate.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  expandable: PropTypes.shape({}),
  typeList: PropTypes.string,
  setCurrentHoverRowList: PropTypes.func,
};

TableTemplate.defaultProps = {
  expandable: {},
  typeList: '',
  setCurrentHoverRowList: () => {},
};

export default TableTemplate;
