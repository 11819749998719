import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SynthesisDrawer from '../shared/recordElements/SynthesisDrawer';
import ShowRowsInfo from '../shared/recordElements/ShowRowsInfo';
import { getReferenceFileAction } from '../../business/actions/referenceFileAction';
import RecordsList from '../shared/recordElements/RecordsList';
import { getSoundsAction } from '../../business/actions/soundsAction';
import SkeletonList from '../shared/skeletons/SkeletonList';
import RecordChartsModal from '../shared/recordElements/charts/RecordChartsModal';

const ArchivesSettings = () => {
  const { all } = useSelector((state) => state.mobiles);
  const { soundsList, loading } = useSelector((state) => state.sounds);
  const { referenceFile } = useSelector((state) => state.referenceFile);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentNumberMobile, setCurrentNumberMobile] = useState(null);
  const [typeChart, setTypeChart] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!soundsList) {
      dispatch(getSoundsAction.request());
    }
  }, []);

  const getMobileId = () => {
    const mobile = all?.find((item) => item.numberMobile === currentNumberMobile);
    if (mobile) {
      const { _id: id } = mobile;
      return id;
    }
    return null;
  };

  const handleShowChartModal = useCallback((record, type) => {
    setCurrentRecord(record);
    setIsOpenModal(true);
    setTypeChart(type);
  }, []);

  useEffect(() => {
    const mobileId = getMobileId();
    if (currentNumberMobile && mobileId) {
      dispatch(getReferenceFileAction.request(mobileId));
    }
  }, [currentNumberMobile]);

  useEffect(() => {
    if (currentNumberMobile && referenceFile) {
      handleShowChartModal(referenceFile);
    }
  }, [referenceFile]);

  const handleShowDrawer = useCallback((record) => {
    setCurrentRecord(record);
    setIsOpenDrawer(true);
  }, []);

  const toggleShowAllRowsInfo = () => {
    if (expandedRowKeys.length === 0) {
      const infosAnalysis = soundsList?.map((sound) => sound.blockChainHash);
      setExpandedRowKeys(infosAnalysis);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const memoizedRecordsList = useMemo(() => (
    <RecordsList
      recordsList={soundsList}
      handleShowChartModal={handleShowChartModal}
      handleShowDrawer={handleShowDrawer}
      expandedRowKeys={expandedRowKeys}
      setExpandedRowKeys={setExpandedRowKeys}
      typeChart="pyramidDensityVector"
    />
  ), [expandedRowKeys, soundsList, handleShowChartModal, handleShowDrawer]);

  if (loading) return <SkeletonList rows={10} />;

  return (
    <Layout>
      <ArrowLeftOutlined className="come-back" onClick={() => navigate(-1)} />
      { isOpenModal
      && (
        <RecordChartsModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          currentRecord={currentRecord}
          setCurrentNumberMobile={setCurrentNumberMobile}
          currentNumberMobile={currentNumberMobile}
          typeChart={typeChart}
        />
      )}
      { isOpenDrawer && (
      <SynthesisDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        currentRecord={currentRecord}
      />
      )}
      <ShowRowsInfo
        expandedRowKeys={expandedRowKeys}
        toggleShowAllRowsInfo={toggleShowAllRowsInfo}
      />
      {memoizedRecordsList}
    </Layout>
  );
};

export default ArchivesSettings;
