import { useMap } from 'react-leaflet';
import { useMemo } from 'react';
import { getBoundsMinMax } from '../../../utils/utils';

const ChangeMapPosition = ({ bounds }) => {
  const map = useMap();
  useMemo(() => {
    if (bounds && bounds.length > 0) {
      const fitBounds = getBoundsMinMax(bounds);
      map.fitBounds(fitBounds);
    }
  }, [bounds, map]);
};

export default ChangeMapPosition;
