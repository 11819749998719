import { call, put, takeEvery } from 'redux-saga/effects';
import { REFERENCEFILE, getReferenceFileAction } from '../actions/referenceFileAction';
import { getReferenceFileService } from '../services/referenceFileService';

export function* getReferenceFile({ payload }) {
  try {
    const referenceFile = yield call(getReferenceFileService, payload);
    const data = referenceFile && referenceFile.data;

    if (data) {
      yield put(getReferenceFileAction.success(data));
    } else {
      yield put(getReferenceFileAction.error('Pas de réference file disponible'));
    }
  } catch (err) {
    yield put(getReferenceFileAction.failure(err));
  }
}

export function* referenceFileSaga() {
  yield takeEvery(REFERENCEFILE.FETCH.REQUEST, getReferenceFile);
}
