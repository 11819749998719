import api from '../utils/query';

export const getAgenciesService = () => {
  const apiUrl = 'api/v1/agency';
  return api.get({ apiUrl });
};

export const postAgencyService = (data) => {
  const apiUrl = 'api/v1/agency';
  return api.create(apiUrl, data);
};

export const patchAgencyService = (data) => {
  const { id } = data;
  const apiUrl = `api/v1/agency/${id}`;
  return api.update(apiUrl, data);
};
