import { Layout } from 'antd';
import React, {
  useState, useMemo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import SynthesisDrawer from '../shared/recordElements/SynthesisDrawer';
import ShowRowsInfo from '../shared/recordElements/ShowRowsInfo';
import RecordsList from '../shared/recordElements/RecordsList';
import RecordChartsModal from '../shared/recordElements/charts/RecordChartsModal';
import { getAnalysisAction } from '../../business/actions/analysisAction';
import UpdateData from '../shared/UpdateData';

const Sounds = () => {
  const { analysis } = useSelector((state) => state.analysis);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [typeChart, setTypeChart] = useState(null);

  const handleShowChartModal = useCallback((record, type) => {
    setCurrentRecord(record);
    setTypeChart(type);
    setIsOpenModal(true);
  }, []);

  const handleShowDrawer = useCallback((record) => {
    setCurrentRecord(record);
    setIsOpenDrawer(true);
  }, []);

  const toggleShowAllRowsInfo = () => {
    if (expandedRowKeys.length === 0) {
      const infosAnalysis = analysis?.map((sound) => sound.blockChainHash);
      setExpandedRowKeys(infosAnalysis);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const memoizedRecordsList = useMemo(() => (
    <RecordsList
      recordsList={analysis}
      handleShowChartModal={handleShowChartModal}
      handleShowDrawer={handleShowDrawer}
      expandedRowKeys={expandedRowKeys}
      setExpandedRowKeys={setExpandedRowKeys}
      typeChart="spectralDensityFiltered"
    />
  ), [expandedRowKeys, handleShowChartModal, handleShowDrawer]);

  return (
    <Layout className="sounds">
      { isOpenModal
      && (
        <RecordChartsModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          currentRecord={currentRecord}
          typeChart={typeChart}
        />
      )}
      { isOpenDrawer && (
      <SynthesisDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        currentRecord={currentRecord}
        type="analysis"
      />
      )}
      <div className="sounds-list-options">
        <ShowRowsInfo
          expandedRowKeys={expandedRowKeys}
          toggleShowAllRowsInfo={toggleShowAllRowsInfo}
        />
        <UpdateData stateToUpdate={getAnalysisAction} timeToRefresh={18000} />
      </div>
      {memoizedRecordsList}
    </Layout>
  );
};

export default Sounds;
