import { Button, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LineChartOutlined } from '@ant-design/icons';
import TableTemplate from '../TableTemplate';
import FilterColumnSearch from '../FilterColumnSearch';
import { formatDate, getIconQualification, setUniqueList } from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';

const RecordsList = ({
  recordsList,
  handleShowChartModal,
  handleShowDrawer,
  setExpandedRowKeys,
  expandedRowKeys,
  typeChart,
}) => {
  const { initialLang } = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (recordsList) {
      const sortedRecordsList = recordsList.sort((a, b) => b.timeStamp - a.timeStamp);
      sortedRecordsList.forEach((sound) => {
        data.push({
          key: sound.blockChainHash,
          ...sound,
        });
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [recordsList]);

  const renderAgenciesFilter = () => {
    const agenciesFilter = [];
    const agenciesList = setUniqueList(recordsList, 'agency');
    agenciesList?.map((agency) => (
      agenciesFilter.push({ text: agency.agency ? agency.agency : '--', value: agency.agency ? agency.agency : '--' })
    ));
    return agenciesFilter;
  };

  const renderRowExpandable = (data) => {
    const newData = [data];
    const subColumns = [
      {
        title: t('records.expandable.androidID'),
        key: 'androidId',
        dataIndex: 'androidId',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
        width: 80,
      },
      {
        title: t('records.expandable.longitude'),
        ellipsis: true,
        key: 'long',
        dataIndex: 'long',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
        width: 80,
      },
      {
        title: t('records.expandable.latitude'),
        ellipsis: true,
        key: 'lat',
        dataIndex: 'lat',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
        width: 80,
      },
      {
        title: t('records.expandable.file'),
        width: 80,
        dataIndex: 'fileName',
        key: 'fileName',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
      },
      {
        title: t('records.expandable.blockchain'),
        ellipsis: true,
        width: 80,
        dataIndex: 'blockChainHash',
        key: 'blockChainHash',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
      },
      {
        title: t('records.expandable.frequency'),
        ellipsis: true,
        width: 50,
        dataIndex: 'leakFrequency',
        key: 'leakFrequency',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
      },
      {
        title: t('records.expandable.power'),
        ellipsis: true,
        width: 50,
        dataIndex: 'leakPower',
        key: 'leakPower',
        render: (text) => <span>{text}</span>,
        responsive: ['md', 'lg'],
      },
      {
        title: t('records.expandable.vector'),
        ellipsis: true,
        width: 50,
        dataIndex: 'pyramidDensityVector',
        key: 'pyramidDensityVector',
        render: () => (
          <Button onClick={() => handleShowChartModal(data, 'analysis')}>
            <LineChartOutlined />
          </Button>
        ),
        responsive: ['md', 'lg'],
      },
    ];

    return (
      <Table
        rowKey={(sound) => sound.blockChainHash}
        columns={subColumns}
        dataSource={newData}
        pagination={false}
      />
    );
  };

  const primaryColumns = [
    {
      title: t('records.expandable.mobileNumber'),
      key: 'numberMobile',
      dataIndex: 'numberMobile',
      ...FilterColumnSearch('numberMobile'),
      render: (text, record) => (
        <Button onClick={() => handleShowChartModal(record, typeChart)}>
          {text || '--'}
        </Button>
      ),
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
    },
    {
      title: t('records.agency'),
      key: 'agency',
      dataIndex: 'agency',
      render: (text) => <span>{text || 'null'}</span>,
      responsive: ['md', 'lg'],
      width: 80,
      filters: renderAgenciesFilter(),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, sound) => {
        const res = sound.agency ? sound.agency?.startsWith(value) : null;
        return (res);
      },
      sorter: (a, b) => (
        a.agency.localeCompare(b.agency)
      ),
      align: 'center',
    },
    {
      title: t('records.town'),
      key: 'nomCommune',
      dataIndex: 'nomCommune',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 80,
      sorter: (a, b) => a.nomCommune.localeCompare(b.nomCommune),
      align: 'center',
    },
    {
      title: t('records.department'),
      key: 'codeDepartement',
      dataIndex: 'codeDepartement',
      align: 'center',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 30,
    },
    {
      title: t('records.date'),
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timeStamp) => formatDate(timeStamp, t('format.fullDate'), initialLang),
      sorter: (a, b) => a.timeStamp - b.timeStamp,
      width: 80,
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.hour'),
      width: 80,
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timestamp) => formatDate(timestamp, t('format.time'), initialLang),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.decision'),
      width: 60,
      dataIndex: 'decision',
      key: 'decision',
      render: (text) => (text === 1
        ? <span className="red">{t('records.leak')}</span>
        : <span>{t('records.noLeak')}</span>),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.coefficient'),
      width: 80,
      dataIndex: 'coefficient',
      key: 'coefficient',
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.qualification'),
      width: 80,
      dataIndex: 'qualification',
      key: 'qualification',
      responsive: ['md', 'lg'],
      align: 'center',
      render: (text, record) => getIconQualification(record.qualification, record.decision),
    },
    {
      title: t('records.synthesis'),
      dataIndex: 'synthesis',
      key: 'synthesis',
      render: (text, record) => (
        <Button onClick={() => handleShowDrawer(record)}>
          {t('records.infos')}
        </Button>
      ),
      width: 80,
      responsive: ['md', 'lg'],
      align: 'center',
    },
  ];

  return (
    <TableTemplate
      columns={primaryColumns}
      data={tableData}
      typeList="records"
      expandable={{
        expandedRowRender: (sound) => renderRowExpandable(sound),
        onExpandedRowsChange: (keys) => setExpandedRowKeys(keys),
        expandedRowKeys,
      }}
    />
  );
};

RecordsList.propTypes = {
  typeChart: PropTypes.string.isRequired,
  recordsList: PropTypes.arrayOf(PropTypes.shape({
    numberMobile: PropTypes.string.isRequired,
    androidId: PropTypes.string.isRequired,
    long: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    agency: PropTypes.string.isRequired,
  })).isRequired,
  handleShowChartModal: PropTypes.func.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
  setExpandedRowKeys: PropTypes.func.isRequired,
  expandedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RecordsList;
