import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const POST = 'POST';
const FETCH = 'FETCH';

export const ALLPHONENUMBERS = createRequestTypes('ALLPHONENUMBERS', [FETCH]);
export const PHONESNUMBER = createRequestTypes('PHONESNUMBER', [FETCH]);
export const PHONENUMBER = createRequestTypes('PHONENUMBER', [FETCH, POST]);
export const CALIBRATEDPHONES = createRequestTypes('CALIBRATEDPHONES', [FETCH]);

export const getAllPhoneNumbersListAction = {
  request: (payload) => action(ALLPHONENUMBERS.FETCH.REQUEST, payload),
  success: (data) => action(ALLPHONENUMBERS.FETCH.SUCCESS, data),
  error: (data) => action(ALLPHONENUMBERS.FETCH.ERROR, data),
  failure: (error) => action(ALLPHONENUMBERS.FETCH.FAILURE, error),
};

export const getPhonesNumberAction = {
  request: (payload) => action(PHONESNUMBER.FETCH.REQUEST, payload),
  success: (data) => action(PHONESNUMBER.FETCH.SUCCESS, data),
  error: (data) => action(PHONESNUMBER.FETCH.ERROR, data),
  failure: (error) => action(PHONESNUMBER.FETCH.FAILURE, error),
};

export const getPhoneNumberAction = {
  request: (payload) => action(PHONENUMBER.FETCH.REQUEST, payload),
  success: (data) => action(PHONENUMBER.FETCH.SUCCESS, data),
  error: (data) => action(PHONENUMBER.FETCH.ERROR, data),
  failure: (error) => action(PHONENUMBER.FETCH.FAILURE, error),
};

export const getCalibratedPhonesAction = {
  request: (payload) => action(CALIBRATEDPHONES.FETCH.REQUEST, payload),
  success: (data) => action(CALIBRATEDPHONES.FETCH.SUCCESS, data),
  error: (data) => action(CALIBRATEDPHONES.FETCH.ERROR, data),
};

export const postPhoneNumberAction = {
  request: (payload) => action(PHONENUMBER.POST.REQUEST, payload),
  success: (data) => action(PHONENUMBER.POST.SUCCESS, data),
  failure: (error) => action(PHONENUMBER.POST.FAILURE, error),
};
