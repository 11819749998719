import { call, put, takeEvery } from 'redux-saga/effects';
import {
  AGENCIES, AGENCIESDETAILS, AGENCY, getAgenciesAction,
  getAgenciesDetailsAction, patchAgencyAction, postAgencyAction,
} from '../actions/agencyAction';
import { postAgencyService, getAgenciesService, patchAgencyService } from '../services/agencyService';
import { setArrayOfUniqueItems } from '../../utils/utils';

const getAgenciesDataChart = ({ uniqueAgencies, payload }) => {
  const result = [];

  uniqueAgencies.forEach((agency) => {
    const agencyData = payload.filter((item) => item.agency === agency);
    const totalDecision1 = agencyData.filter((item) => item.decision === 1).length;
    const totalDecision0and4 = agencyData
      .filter((item) => item.decision === 0 || item.decision === 4).length;
    const resultData = {
      name: agency,
      chartData: [totalDecision1, totalDecision0and4],
    };
    result.push(resultData);
  });
  return result;
};

export function* getAgencies() {
  try {
    const agencies = yield call(getAgenciesService);
    const data = agencies && agencies.data;
    if (data) {
      yield put(getAgenciesAction.success(agencies.data));
    }
  } catch (err) {
    yield put(getAgenciesAction.failure(err));
  }
}

export function* getAgenciesDetails({ payload }) {
  const uniqueAgencies = setArrayOfUniqueItems({ data: payload, type: 'agencies' });

  const data = getAgenciesDataChart({ uniqueAgencies, payload });

  try {
    yield put(getAgenciesDetailsAction.success({ chartData: data }));
  } catch (error) {
    yield put(getAgenciesDetailsAction.failure(error));
  }
}

export function* postAgency({ payload }) {
  try {
    const agencyResponse = yield call(postAgencyService, payload);
    const agencies = yield call(getAgenciesService);

    const agencyExist = agencies.data.some(
      (agency) => agency.name === payload.name,
    );

    if (agencyResponse && agencyExist) {
      yield put(getAgenciesAction.success(agencies.data));
      yield put(postAgencyAction.success('shared.notification.agency.successAdd'));
    }
  } catch (err) {
    yield put(postAgencyAction.failure(err));
  }
}

export function* patchAgency({ payload }) {
  try {
    yield call(patchAgencyService, payload);
    yield put(patchAgencyAction.success('Le numéro de mobile a été modifié'));
  } catch (err) {
    yield put(patchAgencyAction.failure(err));
  }
}

export function* agenciesSaga() {
  yield takeEvery(AGENCIES.FETCH.REQUEST, getAgencies);
  yield takeEvery(AGENCIESDETAILS.FETCH.REQUEST, getAgenciesDetails);
  yield takeEvery(AGENCY.POST.REQUEST, postAgency);
  yield takeEvery(AGENCY.PATCH.REQUEST, patchAgency);
}
