import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const CustomModal = ({
  isOpenModal, setIsOpenModal, width, children, title,
}) => {
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  return (
    <Modal
      width={width}
      title={title}
      open={isOpenModal}
      onCancel={handleCloseModal}
      footer={null}
    >
      { children }
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  title: '',
  width: 800,
  isOpenModal: false,
};

export default CustomModal;
