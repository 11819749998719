import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormTemplate from './FormTemplate';
import { getAgenciesAction, postAgencyAction } from '../../../business/actions/agencyAction';
import { getAgenciesHeadAction } from '../../../business/actions/agencyHeadAction';
import { regexEmail, renderSelectValues } from '../../../utils/utils';

const AgencyForm = () => {
  const { agenciesHead } = useSelector((state) => state.agenciesHead);
  const { agencies, message } = useSelector((state) => state.agencies);
  const [emails, setEmails] = useState(['dev@alcom-tech.com']);
  const [newEmail, setNewEmail] = useState(null);
  const [isDisabledEmailBtn, setIsDisabledEmailBtn] = useState(true);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAgenciesAction.request());
    dispatch(getAgenciesHeadAction.request());
  }, []);

  const onCancel = () => {
    form.resetFields();
  };

  const onFinish = (data) => {
    const resultPromise = [];
    const {
      name, agencyHead, hour, minute,
    } = data;

    if (name && hour && minute && emails.length > 0) {
      resultPromise.push(dispatch(postAgencyAction.request({
        name,
        mail: emails,
        agency_head_id: agencyHead,
        daly_mail_hour: hour,
        daly_mail_minute: minute,
      })));
    } else {
      Promise.reject();
    }
    return Promise.allSettled(resultPromise);
  };

  const onSuccess = () => {
    notification.success({
      message: t('shared.notification.success'),
      description: t(message),
    });
    form.resetFields();
    setEmails([emails[0]]);
  };

  const handleAddEmail = () => {
    if (newEmail) {
      setEmails([...emails, newEmail]);
      setNewEmail(null);
      setIsDisabledEmailBtn(true);
      form.resetFields(['email']);
    }
  };

  const variables = [
    {
      label: t('settings.agency.name'),
      name: 'name',
      rules: [
        {
          required: true,
          message: t('settings.agency.nameHelp'),
        },
        () => ({
          validator(_, value) {
            const isAgencyExist = agencies.some(
              (agency) => agency.name.toLowerCase() === value.toLowerCase(),
            );
            if (isAgencyExist) {
              return Promise.reject(t('settings.agency.nameError'));
            }
            return Promise.resolve();
          },
        }),
      ],
      placeholder: t('settings.agency.placeholderName'),
    },
    {
      label: t('settings.agency.agencyHead'),
      name: 'agencyHead',
      type: 'select',
      selectValues: renderSelectValues(agenciesHead),
      placeholder: t('settings.agency.placeholderAgencyHead'),
    },
    {
      label: t('settings.agency.email'),
      name: 'email',
      type: 'addToList',
      list: emails,
      isDisabledBtn: isDisabledEmailBtn,
      setList: setEmails,
      onClick: handleAddEmail,
      rules: [
        {
          pattern: regexEmail,
          message: t('settings.agency.emailHelp'),
        },
        () => ({
          validator(_, value) {
            const isValideEmail = regexEmail.test(value);
            const hasEmails = emails.length > 0;
            const isEmailExist = hasEmails && emails.some(
              (email) => email.toLowerCase() === value?.toLowerCase(),
            );

            if (!value) {
              setNewEmail(null);
              form.resetFields(['email']);
              setIsDisabledEmailBtn(true);
              return Promise.resolve();
            }
            if (isValideEmail) {
              setIsDisabledEmailBtn(false);
              setNewEmail(value);
              Promise.resolve();
            }

            if (isEmailExist) {
              return Promise.reject(t('settings.agency.emailError'));
            }

            return Promise.resolve();
          },
        }),
      ],
      placeholder: t('settings.agency.placeholderEmail'),
    },
    {
      label: t('settings.agency.hour'),
      name: 'hour',
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.agency.hourHelp'),
        },
      ],
      placeholder: t('settings.agency.placeholderHour'),
    },
    {
      label: t('settings.agency.minute'),
      name: 'minute',
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.agency.minuteHelp'),
        },
      ],
      placeholder: t('settings.agency.placeholderMinute'),
    },
  ];

  return (
    <FormTemplate
      className="settings-form"
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onSuccess={onSuccess}
      onFinishApiCall={(event) => onFinish(event)}
      onCancel={onCancel}
    />
  );
};

export default AgencyForm;
