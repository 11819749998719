import { call, put, takeEvery } from 'redux-saga/effects';
import getEnvelopeFilterService from '../services/envelopeFilterService';
import { ENVELOPEFILTER, getEnvelopeFilterAction } from '../actions/envelopeFilterAction';

export function* getEnvelopeFilter() {
  try {
    const envelopeFilter = yield call(getEnvelopeFilterService);
    const data = envelopeFilter && envelopeFilter.data;

    if (data) {
      yield put(getEnvelopeFilterAction.success(data));
    }
  } catch (err) {
    yield put(getEnvelopeFilterAction.failure(err));
  }
}

export function* getEnvelopeFilterSaga() {
  yield takeEvery(ENVELOPEFILTER.FETCH.REQUEST, getEnvelopeFilter);
}
